<template>
  <v-container
    id="pro-register"
    class="fill-height justify-center mt-16"
    tag="section"
  >
    <div class="d-flex flex-wrap justify-center mb-2 mt-16" style="width: 100%">
      <img src="@/assets/logo/Logo-Verde.png" height="50px" />
    </div>

    <div
      class="d-flex flex-wrap justify-center mb-12"
      style="width: 100%; font-size: 20px; font-weight: 300;"
    >
      <h4 style="font-weight: 400;">
        {{ $t("Genetica") }} · {{ $t("FarmacoGenetica") }}
      </h4>
    </div>

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          class="px-5 py-3"
        >
          <div class="text-center mb-8">
            <h1 class="display-3 font-weight-bold mb-2" style="color: #1C093A;">
              {{ $t("registrate") }}
            </h1>
            <span>
              {{ $t("prueba gratuita_de_3_dias") }}
            </span>
          </div>
          <!-- <template v-slot:heading>
              </template> -->
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(signup)">
              <v-card-text class="text-center pa-0">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="email"
                >
                  <v-text-field
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="crendentials.email"
                    :label="$t('Direccion email') + '*'"
                    :error-messages="errors"
                    color="secondary"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                >
                  <v-text-field
                    autocomplete="off"
                    rounded
                    outlined
                    v-model="crendentials.password"
                    :label="$t('Contrasena') + '*'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :error-messages="errors"
                    color="primary"
                    @click:append.prevent="showPassword = !showPassword"
                  />
                </validation-provider>
                <v-select
                  v-model="selectedJob"
                  :items="jobItems"
                  item-text="display"
                  item-value="value"
                  :label="$t('Perfil')"
                  rounded
                  outlined
                ></v-select>
                <v-select
                  v-model="selectedPlan"
                  :items="planItems"
                  item-text="display"
                  item-value="value"
                  :label="$t('plan')"
                  rounded
                  outlined
                ></v-select>
              </v-card-text>
              <v-card-actions class="pa-0 justify-center">
                <v-btn
                  default
                  rounded
                  color="primary"
                  type="submit"
                  class=" mr-0 ml-2"
                >
                  {{ $t("Registrarse") }}
                </v-btn>
              </v-card-actions>
              <br />
              <v-card-actions class="pa-0 justify-center">
                {{ $t("ya_tienes_cuenta")
                }}<a class="ml-1" @click="$router.push({ name: 'Login' })">
                  {{ $t("identificate") }}</a
                >
              </v-card-actions>
            </form>
          </validation-observer>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
    <div style="color: white;">
      <span
        >{{ $t("al_registrarte_estas_aceptando nuestros") }}
        <a style="color: #6ffdbd;"
          @click="
            goToExternal('https://mylogygenomics.com/terminos-y-condiciones/')
          "
          >{{ $t("terminos_y_condiciones") }}</a
        >
        {{ $t("y_nuestra") }}<a class="ml-1" style="color: #6ffdbd;"  @click="
            goToExternal('https://mylogygenomics.com/privacy-policy/')
          ">{{ $t("politica_privacidad") }}</a></span
      >
    </div>
    <v-row class="my-16" justify="center">
      <v-col cols="12" sm="6">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Ver política de privacidad
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 relativo a la protección de los datos personales de las personas físicas y con la Ley orgánica 3/2018 de 5 de diciembre, Protección de datos personales y garantía de los derechos digitales, la información personal que nos pueda facilitar quedará debidamente registrada e incorporada a los sistemas de tratamiento de datos responsabilidad de EUROESPES S.A, con la finalidad de tramitar su solicitud. Por tal motivo, si usted no está conforme con el tratamiento descrito, no podremos procesar el objeto de su solicitud. Estos datos personales no serán comunicados a ningún destinatario salvo a aquellos que usted nos autorice o así venga exigido por una ley. Usted podrá ejercer los derechos de acceso, rectificación, supresión, limitación de algún tratamiento específico, portabilidad, decisiones automatizadas y oposición al tratamiento. Para el ejercicio de estos derechos, así como para obtener información adicional sobre el tratamiento de sus datos personales, puede consultar la información adicional en nuestra página web https://mylogygenomics.com/privacy-policy/
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PagesRegister",
  components: {},
  data: () => ({
    showPassword: false,
    selectedPlan: "PLAN1",
    selectedJob: "doctor",
    crendentials: {
      password: "",
      email: "",
      type: "PRO"
    }
  }),
  computed: {
    ...mapGetters("app", ["siteURL"]),
    planItems() {
      return [
        {
          display: this.$t("plan_basico"),
          value: "PLAN1"
        },
        {
          display: this.$t("plan_avanzado"),
          value: "PLAN2"
        }
      ];
    },
    jobItems() {
      return [
        {
          display: this.$t("farmaceutico"),
          value: "pharmacist"
        },
        {
          display: this.$t("medico"),
          value: "doctor"
        },
        {
          display: this.$t("investigador"),
          value: "investigador"
        },
      ];
    }
  },
  mounted() {
    if (this.$route.query.plan) {
      this.selectedPlan = this.$route.query.plan;
    }
  },
  methods: {
    ...mapActions("auth", ["attemptSignup"]),
     goToExternal(url) {
      window.open(url, "_blank");
    },
    signup() {
      let self = this;
      self.$nprogress.start();
      self
        .attemptSignup(this.crendentials)
        .then(response => {
          self.$router.push({ name: "ConfirmationSent" });
        })
        .catch(error => {
          self.$dialog.error({
            text: self.$t(error.message),
            title: self.$t(`Algo salio mal al registrarse`),
            icon: false,
            actions: [self.$t("Cerrar")]
          });
        })
        .then(() => {
          self.$nprogress.done();
        });
    }
  }
};
</script>

<style lang="scss" scope>
  .v-list {
    background: white !important;
    div:not(.v-list-item--active) .v-list-item__content {
      color: black !important
    }
  }
</style>